<template>
  <ProfileEdit
    :avatar="getAvatar"
  />
</template>

<script>
import ProfileEdit from '@/components/views/VProfileEdit'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  setup () {
    const store = useStore()

    const getAvatar = computed(() => {
      return store.getters['auth/getUser']?.avatar?.[0][0].url
    })

    return {
      getAvatar
    }
  },
  components: {
    ProfileEdit
  }
}
</script>
